import { FeamEntities, IAviationStackFlightData, IAviationStackResponse } from "../models/feam-entities";
import axios from "axios";

class AviationStackflightDataService {
  #baseUrl: string = "https://api.aviationstack.com/v1";
  #flightsPath: string = "flights";
  #airlinesPath: string = "airlines";
  #access_key: string = "b1b60d2be4d3b95210f57b9b854a18f1";

  async getByArrivalStation(
    station: string,
    airline: string,
    date: string,
    registration: string
  ): Promise<IAviationStackFlightData[]> {
    try {
      const apiClient = axios.create({
        baseURL: this.#baseUrl,
      });
      const response = await apiClient.get<IAviationStackResponse>(`${this.#flightsPath}`, {
        params: {
          access_key: this.#access_key,
          arr_iata: station,
          airline_name: airline,
          flight_date: date
        },
      });
      if (response.data.data.length === 0) {
        const splitAirline = airline.split(" ");
        const splitResponse = await apiClient.get<IAviationStackResponse>(`${this.#flightsPath}`, {
          params: {
            access_key: this.#access_key,
            arr_iata: station,
            airline_name:
              splitAirline.length > 2
                ? splitAirline[0] + " " + splitAirline[1]
                : splitAirline[0],
            flight_date: date
          },
        });
        if(!registration){
          return splitResponse.data.data;
        }else{
          return splitResponse.data.data.filter(
            f => f.aircraft != null && f.aircraft.registration === registration
          );
        }
      }
      if(!registration){
        return response.data.data;
      }else{
        return response.data.data.filter(
          f => f.aircraft != null && f.aircraft.registration === registration
        );
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }
  async getByDepartureStation(
    station: string,
    airline: string,
    date: string,
    registration: string
  ): Promise<IAviationStackFlightData[]> {
    try {
      const apiClient = axios.create({
        baseURL: this.#baseUrl,
      });
      const response = await apiClient.get<IAviationStackResponse>(`${this.#flightsPath}`, {
        params: {
          access_key: this.#access_key,
          dep_iata: station,
          airline_name: airline,
          flight_date: date
        },
      });
      if (response.data.data.length === 0) {
        const splitAirline = airline.split(" ");
        const splitResponse = await apiClient.get<IAviationStackResponse>(`${this.#flightsPath}`, {
          params: {
            access_key: this.#access_key,
            dep_iata: station,
            airline_name:
              splitAirline.length > 2
                ? splitAirline[0] + " " + splitAirline[1]
                : splitAirline[0],
            flight_date: date
          },
        });
        if(!registration){
          return splitResponse.data.data;
        }else{
          return splitResponse.data.data.filter(
            f => f.aircraft != null && f.aircraft.registration === registration
          );
        }
      }
      if(!registration){
        return response.data.data;
      }else{
        return response.data.data.filter(
          f => f.aircraft != null && f.aircraft.registration === registration
        );
      }
    } catch (err: any) {
      console.log(err.response?.data || err);
    }
    return [];
  }

  async getAirlinesByName(
    airline: string
  ): Promise<any> {
    try {
      const apiClient = axios.create({
        baseURL: this.#baseUrl,
      });
      const response = await apiClient.get<any>(`${this.#airlinesPath}`, {
        params: {
          access_key: this.#access_key,
          search: airline,
        },
      });
      if (response.data.data.length === 0) {
        const splitAirline = airline.split(" ");
        const splitResponse = await apiClient.get<any>(`${this.#airlinesPath}`, {
          params: {
            access_key: this.#access_key,
            airline_name:
              splitAirline.length > 2
                ? splitAirline[0] + " " + splitAirline[1]
                : splitAirline[0],
          },
        });
          return splitResponse.data.data.find((x: { airline_name: string; }) =>x.airline_name === airline);
      }
        return response.data.data.find((x: { airline_name: string; }) =>x.airline_name === airline);
    } catch (err: any) {
      console.log(err.response?.data || err);
      return null;
    }
  }
}

const aviationStackflightDataService = new AviationStackflightDataService();
export default aviationStackflightDataService;

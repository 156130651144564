import { FeamEntities, IAviationStackFlightData, IProps } from "../../../models/feam-entities";
import { FeamFormArrayFieldInput, FeamFormArrayTimeFieldInput } from "../../common";
import CwsMultiTailDescriptionTextArea from "./CwsMultiTailDescriptionTextArea";
import { usePrint } from "../../../PageLayout";
import { isMobile, isIOS } from "react-device-detect";
import { useState } from "react";
import { getTimeFormat } from "../../../models/feam-utils";
import { aviationStackflightDataService } from "../../../services";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import * as BootStrapIcon from "react-bootstrap-icons";

export default function CwsMultiTailFields(props: IProps.ICwsMultiTailFieldsProps<FeamEntities.ITailDetail>) {
  const { isPrint } = usePrint();
  const [isLoaded, setIsLoaded] = useState(true);
  const { formProps, arraySelector, itemIndex, station, airlineName, flightDate } = props;
  const [flightDataLoaded, setFlightDataLoaded] = useState(false);
  const [arrivalFlights, setArrivalFlights] = useState<any[]>([]);
  const [departureFlights, setDepartureFlights] = useState<any[]>([]);

  return (
    <>
      <LoadingOverlayWrapper className="feam-postion-fixed" active={!isLoaded} text="Loading Flight Data..." spinner>
        <div key={`multiTail_${itemIndex}`}>
          <div className={`d-flex d-inline-flex customer-worksheet-multiletail-gap ${isPrint && isMobile && isIOS ? "mb-0" : "mb-1"}`}>
            <div className="d-flex justify-content-start ">
              <div className="d-flex d-inline-flex gap-1 customer-worksheet-multiletail-control-width">
                {/*//@ts-ignore */}
                <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="tail" className="customer-worksheet-form-control" />
                <i
                  role="button"
                  title="Search"
                  className="ms-1"
                  onClick={() => {
                    searchTailHandler(itemIndex);
                  }}
                >
                  <BootStrapIcon.Search size={16} />
                </i>
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="flightNum" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormArrayFieldInput formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="gateNum" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                {/*//@ts-ignore */}
                {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].eta)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="eta" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
                {/*//@ts-ignore */}
                {isPrint && !formProps.values[arraySelector][itemIndex].eta && <input type="text" className="form-control customer-worksheet-form-control" />}
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                {/*//@ts-ignore */}
                {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].ata)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="ata" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
                {/*//@ts-ignore */}
                {isPrint && !formProps.values[arraySelector][itemIndex].ata && <input type="text" className="form-control customer-worksheet-form-control" />}
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                {/*//@ts-ignore */}
                {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].etd)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="etd" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
                {/*//@ts-ignore */}
                {isPrint && !formProps.values[arraySelector][itemIndex].etd && <input type="text" className="form-control customer-worksheet-form-control" />}
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                {/*//@ts-ignore */}
                {(!isPrint || (isPrint && formProps.values[arraySelector][itemIndex].atd)) && <FeamFormArrayTimeFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="atd" onBlur={(e) => { formProps.handleBlur(e) }} className="form-control" />}
                {/*//@ts-ignore */}
                {isPrint && !formProps.values[arraySelector][itemIndex].atd && <input type="text" className="form-control customer-worksheet-form-control" />}
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-control-width">
                <FeamFormArrayFieldInput type="text" formProps={formProps} feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="gu" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="apu" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine1" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine2" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine3" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="oilEngine4" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine1" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine2" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine3" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="hydEngine4" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="materialsNitrogen" className="customer-worksheet-form-control" />
              </div>
            </div>
            <div className="d-flex justify-content-start ">
              <div className="customer-worksheet-multiletail-min-control-width">
                <FeamFormArrayFieldInput formProps={formProps} isNumeric feamArraySelector={arraySelector} feamItemIndex={itemIndex} feamSelector="materialsOxygen" className="customer-worksheet-form-control" />
              </div>
            </div>
          </div>
          <div className={`d-flex d-inline-flex w-100 ${isPrint && isMobile && isIOS ? "feam-mb-2" : "mb-1"}`}>
            <label className="d-flex flex-shrink-0 customer-worksheet-label customer-worksheet-label">DESC</label>
            <div className="d-flex d-inline-flex customer-worksheet-form-control w-100 gap-2">
              {/*//@ts-ignore */}
              <CwsMultiTailDescriptionTextArea arraySelector={arraySelector} itemIndex={itemIndex} value={formProps.values.tailDetails[itemIndex].workDetails[0]?.description ?? ''} formProps={formProps} />
              <div className="d-flex d-inline-flex">
                <div className="d-flex align-items-center">
                  <div className="form-check d-inline-flex gap-1">
                    {/*//@ts-ignore */}
                    <input type="checkbox" name={`${arraySelector}.${itemIndex}.ron`} checked={formProps.values.tailDetails[itemIndex]?.ron ?? false} onChange={formProps.handleChange} className="form-check-input" />
                    <label className="customer-worksheet-label form-check-label pt-1" >
                      RON
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex  d-inline-flex">
                <div className="d-flex align-items-center">
                  <div className="form-check d-inline-flex gap-1">
                    {/*//@ts-ignore */}
                    <input type="checkbox" name={`${arraySelector}.${itemIndex}.towing`} checked={formProps.values.tailDetails[itemIndex]?.towing ?? false} onChange={formProps.handleChange} className="form-check-input" />
                    <label className="customer-worksheet-label form-check-label pt-1">
                      Towing
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={flightDataLoaded} size="lg" centered={true}>
          <ModalHeader className="text-uppercase">
            Flight Data Selection
          </ModalHeader>
          <ModalBody className="p-3">
            <div className="ps-3  pe-3" style={{ minHeight: "200px" }}>
              <table className="table table-striped advanced-search-table">
                <thead>
                  <tr className="text-uppercase">
                    <th scope="col" className="p-0 text-start">Flight Number</th>
                    <th scope="col" className="p-0 text-start">ETA</th>
                    <th scope="col" className="p-0 text-start">ATA</th>
                    <th scope="col" className="p-0 text-center"></th>
                  </tr >
                </thead >
                <tbody>
                  {arrivalFlights?.map((item: any, index) => {
                    return (
                      <tr key={`${item.flight.number}_${index}`}>

                        {/** @ts-ignore */}
                        <td className=" text-start">{item.flight.number}</td>
                        {/** @ts-ignore */}
                        <td className=" text-start">{getTimeFormat(item.arrival.scheduled)}</td>
                        <td className=" text-start">{getTimeFormat(item.arrival.actual)}</td>
                        <td className="text-center text-nowrap gap-1">
                          <a className="link-offset-2 link-underline link-underline-opacity-100" href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setArrivalData(item);
                              if (departureFlights.length > 0) {
                                //@ts-ignore
                                const tailValue = formProps.values[arraySelector][index].tail;
                                const selectedFlight = departureFlights.find(x => x.aircraft != null && x.aircraft.registration === tailValue);
                                if (selectedFlight) {
                                  setDepartureData(selectedFlight);
                                }
                              }
                              setFlightDataLoaded(false);
                            }}>Select</a></td>
                      </tr>)
                  })
                  }
                </tbody>
              </table ></div >
          </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-outline-secondary" onClick={() => setFlightDataLoaded(false)}>Close</button >
          </ModalFooter>
        </Modal>
      </LoadingOverlayWrapper>
    </>
  )

  async function searchTailHandler(index: number) {
    if (!formProps.values.d407id) {
      //@ts-ignore
      const tailValue = formProps.values[arraySelector][index].tail;
      if (!tailValue || !station || !airlineName || !flightDate) {
        alert('Tail, Flight Date, Customer and Station are required to fetch flight info.');
        return false;
      }
      setIsLoaded(false);

      const response = await Promise.all([aviationStackflightDataService.getByArrivalStation(station, airlineName, flightDate, tailValue), aviationStackflightDataService.getByDepartureStation(station, airlineName, flightDate, tailValue)]);
      if (response[0].length === 0) {
        alert("No flight data");
      }
      if (response[0].length === 1) {
        setArrivalData(response[0][0]);
        if (response[1].length > 0) {
          setDepartureData(response[1][0]);
        }
      }
      else if (response[0].length > 1) {
        setArrivalFlights(response[0]);
        if (response[1].length > 1) {
          setDepartureFlights(response[1]);
        }
        setFlightDataLoaded(true);
      }
      setIsLoaded(true);
    }
  }

  function setDepartureData(response: IAviationStackFlightData) {
    formProps.setFieldValue(`${arraySelector}[${itemIndex}].etd`, getTimeFormat(response.departure.scheduled));
    formProps.setFieldValue(`${arraySelector}[${itemIndex}].atd`, getTimeFormat(response.departure.actual));
  }

  function setArrivalData(response: IAviationStackFlightData) {
    if (!formProps.values.tail) {
      formProps.setFieldValue(`${arraySelector}[${itemIndex}].tail`, response.aircraft?.registration);
    }
    formProps.setFieldValue(`${arraySelector}[${itemIndex}].flightNum`, response.flight.number);
    formProps.setFieldValue(`${arraySelector}[${itemIndex}].gateNum`, response.arrival.gate);
    formProps.setFieldValue(`${arraySelector}[${itemIndex}].eta`, getTimeFormat(response.arrival.scheduled));
    formProps.setFieldValue(`${arraySelector}[${itemIndex}].ata`, getTimeFormat(response.arrival.actual));
  }
}